<template>
	<b-modal
		id="create-receipt-modal"
		hide-footer
		hide-header
		centered
		body-class="createReceiptModal__body"
		content-class="createReceiptModal__content"
		dialog-class="createReceiptModal__dialog"
	>
		<div class="createReceiptModal__title">「 領収書 」を作成</div>
		<div class="createReceiptModal__datePickerCaption">
			<label class="createReceiptModal__datePickerMark">「 必須 」</label>
			<label class="createReceiptModal__datePickerDescript">半角数字</label>
		</div>
		<div class="createReceiptModal__datePickerArea">
			<div class="createReceiptModal__datePickerTitle">発行日</div>
			<div class="createReceiptModal__datePicker">
				<FunctionalCalendar
					v-model="datePickerData"
					:date-format="'yyyy/mm/dd'"
					:is-date-picker="true"
					:hidden-elements="['leftAndRightDays']"
					:is-multiple="false"
					:is-modal="true"
					:inputDisabled="false"
					:limits="{ min: minDate, max: '' }"
				></FunctionalCalendar>
			</div>
		</div>
		<div class="createReceiptModal__buttonContainer">
			<button class="no-button" @click="onSelectNoClick">キャンセル</button>
			<button class="yes-button" @click="onSelectYesClick">発行</button>
		</div>
	</b-modal>
</template>

<script>
import FunctionalCalendar from '../../DateRange/FunctionalCalendar.vue';
import moment from 'moment';

export default {
	components: {
		FunctionalCalendar,
	},
	data() {
		return {
			datePickerData: {
				dateRange: {
					start: '',
					end: '',
				},
				selectedDate: '',
			},
			minDate: moment(new Date()).format('YYYY/MM/DD'),
		};
	},
	methods: {
		onSelectNoClick() {
			this.$bvModal.hide('create-receipt-modal');
		},
		onSelectYesClick() {
			console.log(this.datePickerData.selectedDate);
			this.$emit('accept-create-receipt', this.datePickerData.selectedDate);
			this.$bvModal.hide('create-receipt-modal');
		},
	},
};
</script>

<style lang="scss" src="./CreateReceiptModal.scss"></style>
