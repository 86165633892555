import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import DocumentService from '../../../services/API/document.service';
import TransferService from '@/services/API/transfer.service';
import CreateReceiptModal from '@/components/Modal/CreateReceipt/CreateReceiptModal.vue';
import HxPdfExporter from '@/components/HxPdfExporter/HxPdfExporter.vue';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import InvoiceService from '@/services/API/invoice.service';

import socketClient from '@/services/SOCKET';
import { sendNotify, toLineMessageTemplate } from '@/utilities';

import moment from 'moment';
import { mapState } from 'vuex';
import Axios from 'axios';
const EXPIRATION = 31536000;
const SELECTIVE_NOTI_CONTENTS = '「 領収書 」 を作成しますか？';

export default {
	name: 'DocumentDownload',
	components: { ScreenHeader, CreateReceiptModal, HxPdfExporter },

	data() {
		return {
			screenIcon: 'download-icon',
			screenName: '書類ダウンロード',
			middleContent: ' 様 ／ ',
			filteredDocuments: [],
			documents: [
				{
					name: '見積書',
					icon: 'quotation.svg',
					key: 'quotation',
					show: false,
					url: '',
				},
				{
					name: '納品書',
					icon: 'delivery_note.svg',
					key: 'delivery_split',
					show: false,
					url: '',
				},
				{
					name: '請求書',
					icon: 'invoice.svg',
					key: 'invoice',
					show: false,
					url: '',
				},
				{
					name: '領収書',
					icon: 'receipt.svg',
					key: 'receipt',
					show: false,
					url: '',
				},
			],
			documentsDownload: {},
			selectiveModalId: 'document-selective-modal',
			selectiveContents: SELECTIVE_NOTI_CONTENTS,
			pdfData: {},
			originEstimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			estimateParticularId: undefined,

			mailTitle: '',
			mailContent: '',
			isSendCreator: false,
		};
	},

	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'appLogo',
			'appLogoPlain',
		]),
		isCreateReceiptShow() {
			if (
				this.projectInfo['creator_finished'] === 1 &&
				!this.documentsDownload['receipt']
			) {
				this._getReceiptData();
				return true;
			} else return false;
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.mailTitle,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}download`
						: `${this.projectInfo['process_client_url']}download`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}download`
					: `${this.projectInfo['process_client_url']}download`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},

	methods: {
		createReceipt() {
			this.$bvModal.show('create-receipt-modal');
		},
		onAcceptCreateReceipt(selectedDate) {
			this.$store.commit('setIsAppProcessing', true);
			this.pdfData.estimateParticular.issue_date = selectedDate;

			setTimeout(() => {
				this.$refs.hxPdfExporterReceipt._renderTemplateString();
			}, 0);
		},
		async onRenderReceipt() {
			console.log('onRenderReceipt');
			let pdfFileData = await this.$refs.hxPdfExporterReceipt.getRenderedPDFTemplate();
			let pdfFileParams = {
				content: pdfFileData,
				project_id: this.projectId,
				file_name: 'receipt.pdf',
			};
			let pdfFileKey = await this._uploadPdf(pdfFileParams);

			await this._updateEstimateParticular(this.estimateParticularId, {
				pdf_receipt_file_key: pdfFileKey,
			});
			this.mailTitle = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 領収書を作成しました`;
			this.mailContent = `領収証を作成しました。`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] [${this.projectInfo['project_name']}] 領収書が届きました`;
			this.mailContent = `領収証が発行されました。`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);
			socketClient.send('receipt_created', {
				userId: this.userId,
				projectId: this.projectId,
				content: 'receipt_created',
			});
			await this.getDocuments();
			await this.filerDocuments();

			this.$store.commit('setIsAppProcessing', false);
		},
		async getDocuments() {
			// Get documents by projectId
			await DocumentService.get(this.projectId).then(async (response) => {
				console.log({ response });
				this.documentsDownload = response.data;
			});
		},

		async filerDocuments() {
			// Filter documents by projectId
			for (let document of this.documents) {
				if (this.documentsDownload[document.key]) {
					document.show = true;
					document.url = await this.getFileUrl(
						this.documentsDownload[document.key],
						EXPIRATION
					);
					if (!document.url) {
						document.show = false;
					}
				}
			}

			let orderDocSet = [];

			let filterOrderConfirmList = [];
			let orderConfirmList = [];
			if (this.documentsDownload['order_confirm']) {
				orderConfirmList = [...this.documentsDownload['order_confirm']];
				orderConfirmList.forEach(async (document, index) => {
					filterOrderConfirmList.push({ name: '', url: '', icon: 'order_confirm.svg' });
					filterOrderConfirmList[index]['name'] =
						index != 0
							? '発注請書_' + document.split('-').at(-1).split('.')[0]
							: '発注請書';
					filterOrderConfirmList[index]['url'] = await this.getFileUrl(
						document,
						EXPIRATION
					);
				});
			}

			let filterPurchaseOrder = [];
			let purchaseOrder = [];

			if (this.documentsDownload['purchase_order']) {
				purchaseOrder = [...this.documentsDownload['purchase_order']];
				purchaseOrder.forEach(async (document, index) => {
					filterPurchaseOrder.push({ name: '', url: '', icon: 'purchase_order.svg' });
					filterPurchaseOrder[index]['name'] =
						index != 0
							? '発注書_' + document.split('-').at(-1).split('.')[0]
							: '発注書';
					filterPurchaseOrder[index]['url'] = await this.getFileUrl(
						document,
						EXPIRATION
					);
				});
			}

			for (let i = 0; i < orderConfirmList.length; i++) {
				orderDocSet.push(filterPurchaseOrder[i]);
				orderDocSet.push(filterOrderConfirmList[i]);
			}
			this.filteredDocuments = [...orderDocSet];
		},

		async getFileUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				return response.data.link;
			} catch (error) {
				console.log(error);
			}
		},

		async downloadDocument(url, name) {
			this.$store.commit('setIsAppProcessing', true);
			console.log(url);
			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			let pdfFile = await Axios.get(url, {
				headers: {
					'Content-Type': 'application/octet-stream',
				},
				responseType: 'blob',
			});
			let fileName = this.projectInfo['project_name'] + '_' + name + '.pdf';
			let listATag = this.$refs.downloadTag;
			let aTag = listATag;
			let objectUrl = window.URL.createObjectURL(pdfFile.data);
			aTag.href = objectUrl;
			aTag.download = fileName;
			console.log(aTag);
			aTag.click();

			this.$store.commit('setIsAppProcessing', false);
		},

		async _getReceiptData() {
			this.$store.commit('setIsAppProcessing', true);

			await this._getEstimateParticularId(this.projectId);
			let pdfData = await this._getInvoice(this.estimateParticularId);
			this.pdfData = { ...pdfData };

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Get Invoice Data
		 * @param {Integer} quoteId EstimateParticular ID
		 * @returns invoiceData
		 */
		async _getInvoice(quoteId) {
			try {
				let response = await InvoiceService.get(quoteId);
				if (!response || response.status !== 200) {
					throw 'Get Invoice failed!';
				}

				console.log('%c Get Invoice successfully!', 'color: green');
				console.log(response.data);
				return response.data.receipt_data;
			} catch (error) {
				console.log(error);
			}
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: red');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateParticularId(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);

				if (response && response.status !== 200) {
					throw response.message;
				}
				console.log('Get estimate particular successfully');
				this.estimateParticularId = response.data['estimate_particular']['id'];
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		/**
		 * Update Estimate particular
		 * @param {Integer} estimateParticularId
		 * @param {Object} body update object
		 */
		async _updateEstimateParticular(estimateParticularId, body) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: body,
				});
				if (response && response.status === 200) {
					console.log('%c Update Estimate particular successfully!', 'color: green');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},
	},
	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this.getDocuments();
		await this.filerDocuments();
		console.log('DocumentDownload mounted', this.documentsDownload, this.documents);

		this.$store.commit('setIsAppProcessing', false);
	},
};
